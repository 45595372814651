export type Campaign = {
    customUrl: string | null
    descriptiveCampaign: string
    endDate: Date
    id: string
    imgCampaign: string
    isExpired: boolean
    name: string
    optinProgram: boolean
    optinProgramStatus: boolean
    programId: string
    startDate: Date
    visibleForAll: boolean
}

export type CampaignListHttpResponse = {
    data: CampaignListDataHttpResponse
    message: string
    statusCode: number
    success: boolean
}

export type CampaignListDataHttpResponse = {
    rows: Campaign[]
    total: number
}

export type LoadCampaignsListRequestProps = {
    status: RequestStatus | null
    successAndHasItems: boolean
}

export enum RequestStatus {
    FETCHING = 'FETCHING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
}