/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from "react";
import { useTitle } from "hooks";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TitlePage } from "./styled";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Accordion, Modal, Notifications } from "design-system/components";
import { Link, useParams } from "react-router-dom";
import RegisterPage from "../RegisterPage/RegisterPage";
import { api } from "data/protocols/services/api";
import Loader from "./Loader";
import ModalLogout from "modal/modal-logout";

const ProgramDetails: FC = () => {
	useTitle("Programa");
	const [modal, setModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [successOptinRequest, setSuccessOptinRequest] =
		useState<boolean>(false);
	const [campaing, setCampaing] = useState<any>([]);
	const { user, campaign } = useSelector((state: RootState) => state);
	const [campaignExtractExists, setcampaignExtractExists] =
		useState<boolean>(false);
	const [showModalLogout, setShowModalLogout] = useState<boolean>(false);
	const params = useParams();
	const baseUrl = process.env.REACT_APP_EXTRACT_BLOB_URL;
	const programId = params.programId;
	const userDoc = user.document_number;

	useEffect(() => {
		setLoading(true);
		window.scrollTo({ behavior: "smooth", top: 0 });
		api
			.fetchCampaign(params.id)
			.then((res) => {
				setCampaing(res.data.data);
			})
			.catch((error: any) => {
				setShowModalLogout(error?.response?.status === 401);
			});
		handleCampaignExtractExists();
		setLoading(false);
	}, []);

	useEffect(() => {
        (function(c: any, l: Document, a: string, r: string, i: string, y?: Element | null){
            c[a] = c[a] || function(...args: any[][]){(c[a].q = c[a].q || []).push(args)};
            const t = l.createElement(r) as HTMLScriptElement;
            t.async = true;
            t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0];
            if (y && y.parentNode) {
                y.parentNode.insertBefore(t, y);
            }
        })(window, document, "clarity", "script", "mm69rd7xp9");
    }, []);

	const handleCampaignExtractExists = async () => {
		await api
			.checkCampaignExtractExists(user.document_number, params.programId)
			.then((res) => {
				setcampaignExtractExists(res);
			})
			.catch((error: any) => {
				if (error?.response?.status === 401) {
					setShowModalLogout(true);
				}
			});
	};

	useEffect(() => {
		if (successOptinRequest) {
			setTimeout(() => setSuccessOptinRequest(false), 4000);
		}
	}, [successOptinRequest]);

	if (loading) {
		return (
			<div className="container">
				<div className="breadcrumb pt-4 pb-2">
					<Link to="/">
						<p className="breadcrumb-active">início</p>
					</Link>
					<p className="breadcrumb-arrow">{">"}</p>
					<Link to="/programas-parceiros">
						<p className="breadcrumb-active">programas parceiros</p>
					</Link>
					<p className="breadcrumb-arrow">{">"}</p>
					<p className="breadcrumb-no-active">{campaing.name}</p>
				</div>
				<Loader />
			</div>
		);
	}
	return (
		<>
			<ModalLogout isOpen={showModalLogout} />
			{successOptinRequest ? (
				<Notifications message="Você aceitou participar do programa" />
			) : (
				""
			)}
			<>
				<div className="container">
					<div className="breadcrumb pt-4">
						<Link to="/">
							<p className="breadcrumb-active">início</p>
						</Link>
						<p className="breadcrumb-arrow">{">"}</p>
						<Link to="/programas-parceiros">
							<p className="breadcrumb-active">programas parceiros</p>
						</Link>
						<p className="breadcrumb-arrow">{">"}</p>
						<p className="breadcrumb-no-active">{campaing.name}</p>
					</div>
					<div className="slider">
						<img src={campaing.imgBanner} alt="" />
					</div>
						<TitlePage className="pt-4 pb-4">{campaing.title}</TitlePage>
					</div>

				<div className="container">
					<div className="flex space-between gap-20 sm column">
						<div className="left-side-div">
							<p
								dangerouslySetInnerHTML={{
									__html: campaing.howWorks,
								}}
							/>
							<div className="mt-3 mb-1">
								<div className="accordion-div ">
									<Accordion
										isCampaingContent
										accordionContent={campaing.participantsProducts}
										accordionTitle={
											<div className="pr-2 flex">
												<i className="fa fa-archive orange-icon pr-[20px]"></i>
												<p className="text[16px] font-bold title-acc">
													Produtos participantes
												</p>
											</div>
										}
										accordionIcon={
											<ExpandMoreIcon style={{ color: "red", width: "30px" }} />
										}
									/>
								</div>
							</div>
							<div className="mt-3 mb-1">
								<div className="accordion-div">
									<Accordion
										isCampaingContent
										accordionContent={campaing.regulation}
										accordionTitle={
											<div className="pr-2 flex">
												<i className="fa fa-file-text orange-icon pr-[20px]"></i>
												<p className="text[16px] font-bold title-acc">
													Regulamento do programa
												</p>
											</div>
										}
										accordionIcon={
											<ExpandMoreIcon style={{ color: "red", width: "30px" }} />
										}
									/>
								</div>
							</div>
							<div className="mt-3 mb-1">
								<div className="accordion-div">
									<Accordion
										isCampaingContent
										accordionContent={campaing.faq}
										accordionTitle={
											<div className="pr-2 flex">
												<i className="fa fa-question-circle orange-icon pr-[20px]"></i>
												<p className="text[16px] font-bold title-acc">
													Perguntas frequentes
												</p>
											</div>
										}
										accordionIcon={
											<ExpandMoreIcon style={{ color: "red", width: "30px" }} />
										}
									/>
								</div>
							</div>
						</div>

						<div className="right-side-div">
							<div
								className="box-sign "
								id={user.is_logged ? "isLogged" : "notLogged"}
							>
								<h3 className="text-[20px]">
									<strong>{campaing.name}</strong>
								</h3>

								<div id={user.is_logged ? "isLogged" : "notLogged"}>
									{campaign.optinProgram && campaign.optinStatus ? (
										<p className="bold underline mt-2">
											VOCÊ JÁ ESTÁ CADASTRADO NESTE PROGRAMA.
										</p>
									) : campaign.optinProgram && !campaign.optinStatus ? (
										<>
											<p className="bold underline mt-2">
												VOCÊ ESTÁ INSCRITO NO PROGRAMA, PORÉM PRECISA ACEITAR O
												REGULAMENTO.
											</p>
											<div
												className="red-button pointer"
												onClick={() => setModal(!modal)}
											>
												ACEITAR O REGULAMENTO
											</div>
										</>
									) : (
										<div
											className="red-button pointer"
											onClick={() => setModal(!modal)}
										>
											PARTICIPAR DO PROGRAMA
										</div>
									)}
								</div>
							</div>
 
							{campaing.legacyExtract && (
								<div
									className="box-sign mt-4"
									id={user.is_logged ? "isLogged" : "notLogged"}
								>
									<div className="flex">
										<i className="fa fa-table orange-icon pr-[20px]"></i>
										<p className="font-18 bold title-acc">Extrato Legado</p>
									</div>
									<div className="pr-2 pb-2 flex">
										<span style={{ fontSize: "12px", marginTop: "0"}}>(pontuação da antiga plataforma)</span>
									</div>
									<>
										<p>Aqui você encontra seu histórico de transações. </p>
										<br />
										{campaignExtractExists ? (
											<p className="mt-2">
												clique{" "}
												<a
													className="bold red"
													rel="noreferrer"
													target="_blank"
													href={`${baseUrl}${userDoc}_${programId}.xlsx`}
												>
													aqui
												</a>{" "}
												para baixar seu extrato.
											</p>
										) : (
											<p className="bold red">
												Você não possui extrato disponível.
											</p>
										)}
									</>
								</div>
							)}
							<div className="box-sign mt-4">
								<div className="pr-2 pb-2 flex">
									<i className="fa fa-whatsapp orange-icon pr-[20px]"></i>
									<p className="font-18 bold title-acc">Precisa de ajuda?</p>
								</div>
								<>
									<p
										dangerouslySetInnerHTML={{
											__html: campaing.description,
										}}
									/>
								</>
							</div>

							<div>
								<div className="mt-3 mb-1 none">
									<div className="accordion-div">
										<Accordion
											accordionContent={
												<>
													<p>Histórico de transações do programa anterior</p>
													<br />
													{campaignExtractExists ? (
														<p className="mt-2">
															Clique{" "}
															<a
																className="bold red"
																rel="noreferrer"
																target="_blank"
																href={`${baseUrl}${userDoc}_${programId}.xlsx`}
															>
																aqui
															</a>{" "}
															para baixar o extrato.
														</p>
													) : (
														<p className="bold red">
															Você não possui extrato disponível.
														</p>
													)}
												</>
											}
											accordionTitle={
												<div className="pr-2 flex">
													<i className="fa fa-table orange-icon pr-[20px]"></i>
													<p className="text[16px] font-bold title-acc">
														Extrato Legado
													</p>
												</div>
											}
											accordionIcon={<ExpandMoreIcon />}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
			<Modal
				style={{
					padding: 0,
					width: 800,
				}}
				titleAlign="center"
				open={modal}
				size="MEDIUM"
				content={
					<div className="body-modal">
						<RegisterPage
							optinStatus={campaign.optinStatus}
							isDetailCampaignFlow={true}
							name={campaing.name}
							campaing={campaing}
							optin={campaign.optinProgram}
							url={true}
						/>
					</div>
				}
			/>
		</>
	);
};

export default ProgramDetails;
