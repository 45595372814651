/* eslint-disable sort-keys */
/* eslint-disable no-mixed-spaces-and-tabs */
import { FC, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { RootState } from "store";
import { Link } from "react-router-dom";
import { useTitle } from "hooks";
import { ProgramPageStyled } from "./styled";

import { api } from "data/protocols/services/api";
import {
	Campaign,
	LoadCampaignsListRequestProps,
	RequestStatus,
} from "domain/models/campaign/list";
import { setCurrentCampaign } from "store/slices/campaign";
import { DateHelper } from "utils/functions/date/date";
import ModalLogout from "modal/modal-logout";

const factoryDefaultForm = () => ({
	justNotCurrentCampaign: false,
	campaignName: "",
	startDate: "",
	endDate: "",
});

enum OriginFetching {
	BUTTON_FILTER = "BUTTON_FILTER",
	BUTTON_SHOW_MORE = "BUTTON_SHOW_MORE",
}

const ProgramPage: FC = () => {
	const [campaignsList, setCampaignsList] = useState<Campaign[]>([]);
	const [loadCampaignsListRequestProps, setLoadCampaignsListRequestProps] =
		useState<LoadCampaignsListRequestProps>({
			status: null,
			successAndHasItems: false,
		});
	const [showMessageAboutNoMoreCampaigns, setShowMessageAboutNoMoreCampaigns] =
		useState<boolean>(false);
	const [showMessageNotDataToFilter, setShowMessageNotDataToFilter] =
		useState<boolean>(false);
	const [pageIndex, setPageIndex] = useState<number>(1);
	const [formFilter, setFormFilter] = useState(factoryDefaultForm());
	const [showModalLogout, setShowModalLogout] = useState<boolean>(false);
	const { user } = useSelector((state: RootState) => state);
	const dispatch = useDispatch();

	useTitle("Programas parceiros");

	const dateHelper = new DateHelper();

	const fetchCampaigns = async (
		index: number,
		originFetching?: OriginFetching
	) => {
		setShowMessageNotDataToFilter(false);
		try {
			setShowMessageAboutNoMoreCampaigns(false);
			const list = await api.fetchAllCampaigns(
				user.is_logged
					? buildQueryStringAndFetchCampaigns(index)
					: queryParamsNotLoggedUser(index)
			);
			if (!list.data) {
				if (originFetching === OriginFetching.BUTTON_FILTER) {
					setShowMessageNotDataToFilter(true);
					setCampaignsList([]);
				}

				if (originFetching === OriginFetching.BUTTON_SHOW_MORE) {
					setShowMessageAboutNoMoreCampaigns(true);
				}
			} else {
				setShowMessageAboutNoMoreCampaigns(false);
				setCampaignsList([...list.data.rows]);
				setLoadCampaignsListRequestProps({
					status: RequestStatus.SUCCESS,
					successAndHasItems: list?.data?.rows?.length >= 1,
				});
			}
		} catch (error: any) {
			if (error?.response?.status) {
				setShowModalLogout(true);
			} else {
				setLoadCampaignsListRequestProps({
					status: RequestStatus.ERROR,
					successAndHasItems: false,
				});
			}
		}
	};

	const fetchMoreCampaigns = () => {
		const nextIndex = pageIndex + 1;
		setPageIndex(nextIndex);
		fetchCampaigns(nextIndex, OriginFetching.BUTTON_SHOW_MORE);
	};

	const handleChangeInputValues = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {
			target: { name, value },
		} = e;
		setFormFilter({ ...formFilter, [name]: value });
	};

	const buildQueryStringAndFetchCampaigns = (index: number): string => {
		return queryParamsUserLogged({
			index: index,
			name: formFilter.campaignName,
			current: !formFilter.justNotCurrentCampaign,
			startDate: formFilter.startDate,
			endDate: formFilter.endDate,
		});
	};

	useEffect(() => {
		window.scrollTo({ behavior: "smooth", top: 0 });
	}, []);

	useEffect(() => {
        (function(c: any, l: Document, a: string, r: string, i: string, y?: Element | null){
            c[a] = c[a] || function(...args: any[][]){(c[a].q = c[a].q || []).push(args)};
            const t = l.createElement(r) as HTMLScriptElement;
            t.async = true;
            t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0];
            if (y && y.parentNode) {
                y.parentNode.insertBefore(t, y);
            }
        })(window, document, "clarity", "script", "mm69rd7xp9");
    }, []);

	useEffect(() => {
		if (!loadCampaignsListRequestProps.status) {
			fetchCampaigns(pageIndex);
		}
	}, [loadCampaignsListRequestProps.status]);

	useEffect(() => {
		if (
			showMessageNotDataToFilter &&
			!formFilter.campaignName &&
			!formFilter.startDate &&
			!formFilter.endDate &&
			user.is_logged
		) {
			const firstIndex = 0;
			setPageIndex(firstIndex);
			fetchCampaigns(firstIndex);
		}
	}, [formFilter, showMessageNotDataToFilter]);

	useEffect(() => {
		if (showMessageAboutNoMoreCampaigns) {
			setTimeout(() => {
				setShowMessageAboutNoMoreCampaigns(false);
			}, 5000);
		}
	}, [showMessageAboutNoMoreCampaigns]);

	const queryParamsNotLoggedUser = (index: number) =>
		`Paginate.PageIndex=${index}&Paginate.PageSize=8&CurrentCampaign=true&Name&StartDate&EndDate`;

	const queryParamsUserLogged = (propsFilter: {
		current: boolean;
		endDate: string;
		index: number;
		name: string;
		startDate: string;
	}) => {
		let queryRoot = `Paginate.PageIndex=${propsFilter.index}&Paginate.PageSize=8&CurrentCampaign=${propsFilter.current}&DocumentNumber=${user.document_number}`;
		propsFilter.name
			? (queryRoot = queryRoot.concat(`&Name=${propsFilter.name}`))
			: (queryRoot = queryRoot.concat(`&Name`));
		propsFilter.startDate
			? (queryRoot = queryRoot.concat(`&StartDate=${propsFilter.startDate}`))
			: (queryRoot = queryRoot.concat(`&StartDate`));
		propsFilter.endDate
			? (queryRoot = queryRoot.concat(`&EndDate=${propsFilter.endDate}`))
			: (queryRoot = queryRoot.concat(`&EndDate`));
		return queryRoot;
	};

	const handleClickCard = async (customUrl: string | null) => {
		if (customUrl) {
			if (user?.is_logged) {
				const url = await api.redirectUserAgregaLogged({
					documentNumber: String(user.document_number),
					email: String(user.email),
				});
				window.open(url, "_self");
			}
		}
	};

	const determineLabel = (
		optinProgram: boolean,
		optinStatus: boolean
	): { description: string; title: string } => {
		if (optinStatus && optinProgram) {
			return {
				description: "Você está cadastrado neste programa.",
				title: "Participando",
			};
		}

		if (!optinStatus && optinProgram) {
			return {
				description:
					"Você está inscrito no programa, porém precisa aceitar o regulamento.",
				title: "Ação necessária",
			};
		}

		return {
			description:
				"Você está inscrito no programa, porém precisa aceitar o regulamento.",
			title: "Não participando",
		};
	};

	return (
		<ProgramPageStyled.ContainerPage>
			<ModalLogout isOpen={showModalLogout} />
			<div className="container">
				<div className="breadcrumb pt-4">
					<Link to="/">
						<p className="breadcrumb-active">início</p>
					</Link>
					<p className="breadcrumb-arrow">{">"}</p>
					<p className="breadcrumb-no-active">programas parceiros</p>
				</div>
				<ProgramPageStyled.TitlePage className="pt-4 pb-4">
					Conheça e faça parte dos programas parceiros.
				</ProgramPageStyled.TitlePage>
				{user.is_logged && (
					<Fragment>
						<div>
							<div className="toggle-button-cover">
								<div className="button-cover">
									<div className="button b2" id="button-10">
										<input
											type="checkbox"
											className="checkbox"
											onClick={() =>
												setFormFilter({
													...formFilter,
													justNotCurrentCampaign:
														!formFilter.justNotCurrentCampaign,
												})
											}
											checked={formFilter.justNotCurrentCampaign}
										/>
										<div className="knobs">
											<span>Vigente</span>
										</div>
										<div className="layer"></div>
									</div>
								</div>
							</div>
						</div>
						<ProgramPageStyled.GrayBoxForm>
							<ProgramPageStyled.RowForm>
								<div className="box-form-field">
									<label>Nome da campanha</label>
									<Input
										className="field-row-form"
										name="campaignName"
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											handleChangeInputValues(e)
										}
										placeholder="Escreva"
										value={formFilter.campaignName}
									/>
								</div>

								<div className="box-form-field">
									<label>Inicio</label>
									<Input
										className="field-row-form"
										name="startDate"
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											handleChangeInputValues(e)
										}
										placeholder="00/00/0000"
										type="date"
										value={formFilter.startDate}
									/>
								</div>

								<div className="box-form-field">
									<label>Fim</label>
									<Input
										className="field-row-form"
										name="endDate"
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											handleChangeInputValues(e)
										}
										placeholder="00/00/0000"
										type="date"
										value={formFilter.endDate}
									/>
								</div>

								<button
									id="btn-apply-filter"
									onClick={() =>
										fetchCampaigns(pageIndex, OriginFetching.BUTTON_FILTER)
									}
								>
									Filtrar
								</button>
								<button
									id="btn-clear-form"
									onClick={() => setFormFilter(factoryDefaultForm())}
								>
									Limpar
								</button>
							</ProgramPageStyled.RowForm>
							{showMessageNotDataToFilter && (
								<ProgramPageStyled.MessageWithoutResult>
									Sem resultados para a pesquisa.
								</ProgramPageStyled.MessageWithoutResult>
							)}
						</ProgramPageStyled.GrayBoxForm>
					</Fragment>
				)}

				{loadCampaignsListRequestProps.successAndHasItems && (
					<ProgramPageStyled.CampaignsListContainer>
						{campaignsList.map((campaign) => {
							const howWorkButton = () => {
								if (!campaign?.customUrl) {
									dispatch(
										setCurrentCampaign({
											id: campaign.id,
											optinProgram: campaign.optinProgram,
											optinStatus: campaign.optinProgramStatus,
										})
									);
									window.open(`/programa/${campaign.programId}/${campaign.id}`, '_self');

								} else if (campaign.customUrl && user?.is_logged) {
									
									handleClickCard(campaign.customUrl);
								} else {
									window.open(campaign?.customUrl, '_self');
								}
							}

							return (
								<ProgramPageStyled.BoxCampaign
									title={
										user.is_logged
											? determineLabel(
													campaign.optinProgram,
													campaign.optinProgramStatus
											  ).description
											: ""
									}
									onClick={() => handleClickCard(campaign.customUrl)}
									key={campaign.id}
								>
									{user.is_logged ? (
										<ProgramPageStyled.Figure
											optinStatus={campaign.optinProgramStatus}
											optinProgram={campaign.optinProgram}
										>
											<img
												className="img-campaign"
												src={campaign.imgCampaign}
												alt={`Imagem da campanha ${campaign.name}`}
											/>
											<figcaption>
												<div>
													<p>
														{
															determineLabel(
																campaign.optinProgram,
																campaign.optinProgramStatus
															).title
														}
													</p>
												</div>
											</figcaption>
										</ProgramPageStyled.Figure>
									) : (
										<img
											className="img-campaign"
											src={campaign.imgCampaign}
											alt={`Imagem da campanha ${campaign.name}`}
										/>
									)}
									<ProgramPageStyled.TitleCampaignBox>
										<h4>{campaign.name}</h4>
									</ProgramPageStyled.TitleCampaignBox>
									<ProgramPageStyled.DateCampaignBox>
										<CalendarOutlined />
										<p>
											{" "}
											{dateHelper.convertAmericanFormatToBrazilFormat(
												String(campaign.startDate),
												"/"
											)}{" "}
											até{" "}
											{dateHelper.convertAmericanFormatToBrazilFormat(
												String(campaign.endDate),
												"/"
											)}
										</p>
									</ProgramPageStyled.DateCampaignBox>
									<ProgramPageStyled.DescriptionBox>
										<p>{campaign.descriptiveCampaign}</p>
									</ProgramPageStyled.DescriptionBox>
									<ProgramPageStyled.RowCenterWithBottomMargin>
										<button
											className="button-how-work"
											onClick={howWorkButton}
										>
											Como funciona
										</button>
									</ProgramPageStyled.RowCenterWithBottomMargin>
								</ProgramPageStyled.BoxCampaign>
							);
						})}
					</ProgramPageStyled.CampaignsListContainer>
				)}

				<ProgramPageStyled.RowCenter>
					{showMessageAboutNoMoreCampaigns && (
						<p>Não há mais campanhas para exibir.</p>
					)}
					<>
						{campaignsList.length >= 1 && (
							<ProgramPageStyled.ButtonShowMore
								onClick={() => fetchMoreCampaigns()}
							>
								Mostrar mais
							</ProgramPageStyled.ButtonShowMore>
						)}
					</>
				</ProgramPageStyled.RowCenter>
			</div>
		</ProgramPageStyled.ContainerPage>
	);
};

export default ProgramPage;
