/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import { FC, Fragment, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { HeaderStyled } from "./styled";
import { HeaderImages } from "design-system/images";
import { HeaderProps } from "design-system/domain";
import { colors } from "design-system/theme";
import { RootState } from "store";
import { Http } from "data/protocols/services/api";
import { HttpRegistrationRedirect } from "services/account-user/redirect";
import { StorageHelper } from "utils/functions/storage/storage";
import {
	ModalUserIsNotLogged,
	ModalRedirectComplementAccount,
} from "pages/Modal";
import { UserState } from "store/domain";
import OptinComunication from "pages/Modal/OptinComunication/OptinComunication";
import ModalLogout from "modal/modal-logout";
import { blockRoutesToShowElement } from "utils/consts/blocked-routes-show-header-and-footer";

const Header: FC<HeaderProps> = ({ backgroundColor, hideFirstRowHeader }) => {
	const [showSubItemsMenu, setShowSubItemsMenu] = useState<boolean>(false);
	const [showUserMenu, setshowUserMenu] = useState<boolean>(false);
	const [
		modalRedirectComplementAccountIsOpen,
		setModalRedirectComplementAccountIsOpen,
	] = useState<boolean>(false);
	const [modalUserIsNotLogged, setModalUserIsNotLogged] =
		useState<boolean>(false);
	const [modalOptionComunication, setModalOptionComunication] =
		useState<boolean>(false);
	const [queryParamValue, setQueryParamValue] = useState<string>("");
	const { user } = useSelector((state: RootState) => state);
	const [isRedirect, setIsRedirect] = useState<boolean>(false);
	const [showModalLogout, setShowModalLogout] = useState<boolean>(false);
	const http = new Http();
	const storageHelper = new StorageHelper();
	const [modal, setModal] = useState<boolean>(false);
	const httpRegistrationRedirect = new HttpRegistrationRedirect(
		http,
		storageHelper
	);

	const isElo = location.pathname === "/elo";

	useEffect(() => {
		const redirectUser = localStorage.getItem("user_redirect");
		if (redirectUser) {
			setIsRedirect(true);
		} else {
			setIsRedirect(false);
		}
	}, [isRedirect]);

	const menuItems = [
		{
			customEvent: (_user: UserState) => (window.location.href = "/"),
			link: "/",
			title: "Home",
		},
		{
			customEvent: (_user: UserState) =>
				(window.location.href = "/programas-parceiros"),
			link: "/programas-parceiros",
			title: "Como ganhar pontos",
		},

		{
			customEvent: async () => {
				if (user.is_logged) {
					if (!user.opt_in_terms_and_privacy || !user.opt_in_points) {
						setModalRedirectComplementAccountIsOpen(true);
						return;
					}

					if (user.opt_in_comunication === null) {
						setModalOptionComunication(true);
						return;
					}
					try {
						const log = await httpRegistrationRedirect.getUrlRedirect({
							data: {
								documentNumber: user.document_number as string,
								email: user.email as string,
							},
						});
						setIsRedirect(true);
						localStorage.setItem("user_redirect", "isRedirect");
						window.location.href = log.response.data;
					} catch (error: any) {
						setShowModalLogout(error?.response?.status === 401);
					}
				}
			},
			...(user.is_logged
				? {
					link: "/resgatar",
					title: "Resgatar pontos",
				}
				: {}),
		},
	];

	const accountDetails = [
		{
			customEvent: async () => {
				if (
					user.is_logged &&
					(!user.opt_in_terms_and_privacy || !user.opt_in_points)
				) {
					setModalRedirectComplementAccountIsOpen(true);
				} else if (user.opt_in_comunication === null) {
					setModalOptionComunication(true);
				} else {
					try {
						const log = await httpRegistrationRedirect.getUrlRedirect({
							data: {
								documentNumber: user.document_number as string,
								email: user.email as string,
								routeCatalog: 'my-orders?days=30'
							},
						});
						localStorage.setItem("user_redirect", "isRedirect");
						window.location.href = log.response.data;
					} catch (error: any) {
						setShowModalLogout(error?.response?.status === 401);
					}
				}
			},
			title: "Minha Conta",
		},
		{
			customEvent: async () => {
				if (
					user.is_logged &&
					(!user.opt_in_terms_and_privacy || !user.opt_in_points)
				) {
					setModalRedirectComplementAccountIsOpen(true);
				} else if (user.opt_in_comunication === null) {
					setModalOptionComunication(true);
				} else {
					try {
						const log = await httpRegistrationRedirect.getUrlRedirect({
							data: {
								documentNumber: user.document_number as string,
								email: user.email as string,
								routeCatalog: 'extract'
							},
						});
						window.location.href = log.response.data;
					} catch (error: any) {
						setShowModalLogout(error?.response?.status === 401);
					}
				}
			},
			title: "Extrato de pontos",
		},
		{
			customEvent: (): void => {
				window.location.replace("/logout");
			},
			title: "Sair",
		},
	];

	const handleCloseModal = (): void => {
		setModalRedirectComplementAccountIsOpen(false);
	};



	return (
		<>
			{blockRoutesToShowElement.includes(window.location.pathname) ? <Fragment /> :
				<>
					<ModalLogout isOpen={showModalLogout} />
					{!hideFirstRowHeader && (
						<HeaderStyled.Container
							backgroundColor={backgroundColor}
							hideFirstRowHeader={hideFirstRowHeader}
						>
							<HeaderStyled.LogoLink>
								<HeaderStyled.LogoHeader
									alt={"Logo do ConectaAG"}
									className="pointer"
									src={HeaderImages.logoConecta}
									title="Ao clicar neste logo você será redirecionado(a) para o catálogo de pontos do conecta"
									onClick={async () => {
										if (user.is_logged) {
											if (!user.opt_in_terms_and_privacy || !user.opt_in_points) {
												setModalRedirectComplementAccountIsOpen(true);
											} else if (user.opt_in_comunication === null) {
												setModalOptionComunication(true);
											} else if (
												user.opt_in_terms_and_privacy &&
												user.opt_in_points
											) {
												try {
													const log = await httpRegistrationRedirect.getUrlRedirect(
														{
															data: {
																documentNumber: user.document_number as string,
																email: user.email as string,
															},
														}
													);
													window.location.href = log.response.data;
												} catch (error: any) {
													setShowModalLogout(error?.response?.status === 401);
												}
											}
										} else {
											setQueryParamValue("hotsite");
											setModalUserIsNotLogged(true);
										}
									}}
								/>
							</HeaderStyled.LogoLink>
							<HeaderStyled.UserBoxContainer className={isElo ? "none" : ""}>
								<HeaderStyled.SecondChildNavFixed>
									<HeaderStyled.LogoUser
										src={HeaderImages.logoUser}
										alt="Ícone de usuário no cabeçalho da página"
									/>
									<HeaderStyled.LoginOrNewAccountLabel
										onClick={() => {
											if (user.is_logged) {
												setshowUserMenu(!showUserMenu);
											} else {
												setQueryParamValue("hotsite");
												setModalUserIsNotLogged(true);
											}
										}}
									>
										{user.is_logged ? (
											<>
												Olá, {user.name?.split(" ")[0]} <br />
												Sua conta
											</>
										) : (
											<>
												<strong translate="no">Entre </strong> ou <br />
												<strong translate="no">Cadastre-se</strong>
											</>
										)}
									</HeaderStyled.LoginOrNewAccountLabel>
								</HeaderStyled.SecondChildNavFixed>
								<HeaderStyled.SubMenuHeaderContainer
									id="subMenuHeader"
									showMenu={showUserMenu}
								>
									<HeaderStyled.SubMenuHeaderUserBalanceContainer>
										<HeaderStyled.SubMenuHeaderUserBalanceContainerText>
											<HeaderStyled.SubMenuHeaderText>
												Você tem
											</HeaderStyled.SubMenuHeaderText>
											<HeaderStyled.SubMenuHeaderText
												colorText={colors.primary.cian}
												fontSize={"22.5px"}
												textWeight={"800"}
											>
												{user.points ? Math.floor(user.points).toLocaleString("pt-BR")  + " pontos" : "0 pontos"}
											</HeaderStyled.SubMenuHeaderText>
											<HeaderStyled.SubMenuHeaderText>
												disponíveis para resgate
											</HeaderStyled.SubMenuHeaderText>
										</HeaderStyled.SubMenuHeaderUserBalanceContainerText>
										<HeaderStyled.SubMenuHeaderRedirectButton
											onClick={async () => {
												if (user.is_logged) {
													if (
														!user.opt_in_terms_and_privacy ||
														!user.opt_in_points
													) {
														setModalRedirectComplementAccountIsOpen(true);
													} else if (user.opt_in_comunication === null) {
														setModalOptionComunication(true);
													} else if (
														user.opt_in_terms_and_privacy &&
														user.opt_in_points
													) {
														try {
															const log =
																await httpRegistrationRedirect.getUrlRedirect({
																	data: {
																		documentNumber: user.document_number as string,
																		email: user.email as string,
																	},
																});
															setIsRedirect(true);
															localStorage.setItem("user_redirect", "isRedirect");
															if (isRedirect) {
																window.location.href = `https://conecta.webpremios.digital/extract/`;
															} else {
																window.location.href = log.response.data;
															}
														} catch (error: any) {
															setShowModalLogout(error?.response?.status === 401);
														}
													}
												} else {
													setQueryParamValue("hotsite");
													setModalUserIsNotLogged(true);
												}
											}}
										>
											&gt;
										</HeaderStyled.SubMenuHeaderRedirectButton>
									</HeaderStyled.SubMenuHeaderUserBalanceContainer>
									<HeaderStyled.SubMenuHeaderContainerList>
										<HeaderStyled.SubMenuHeaderList>
											{accountDetails.map((subMenuItems, index: number) => (
												<HeaderStyled.SubMenuHeaderListItem key={index}>
													<HeaderStyled.SubMenuHeaderListLink
														onClick={() => subMenuItems.customEvent()}
													>
														{subMenuItems.title}
													</HeaderStyled.SubMenuHeaderListLink>
												</HeaderStyled.SubMenuHeaderListItem>
											))}
										</HeaderStyled.SubMenuHeaderList>
									</HeaderStyled.SubMenuHeaderContainerList>
								</HeaderStyled.SubMenuHeaderContainer>
							</HeaderStyled.UserBoxContainer>
						</HeaderStyled.Container>
					)}

					<HeaderStyled.NavFixed
						showSubItems={showSubItemsMenu}
						hideFirstRowHeader={hideFirstRowHeader}
						className={isElo ? "none" : ""}
					>
						{isMobile ? (
							<>
								<HeaderStyled.ContainerNavBar>
									<HeaderStyled.ContainerLoginIntems>
										<HeaderStyled.MenuHamburgerMobile
											onClick={() => setShowSubItemsMenu(!showSubItemsMenu)}
										/>
									</HeaderStyled.ContainerLoginIntems>
								</HeaderStyled.ContainerNavBar>

								{showSubItemsMenu && (
									<div>
										<HeaderStyled.NavItemsListMobile>
											{menuItems.map((menuItem, index: number) => (
												<HeaderStyled.ListItemMobile
													key={index}
													title={menuItem.title}
													isActive={window.location.pathname === menuItem.link}
												>
													<a onClick={() => menuItem.customEvent(user)}>
														{menuItem.title}
													</a>
												</HeaderStyled.ListItemMobile>
											))}
										</HeaderStyled.NavItemsListMobile>
									</div>
								)}
							</>
						) : (
							<>
								<HeaderStyled.FirstChildNavFixed>
									<div>
										<HeaderStyled.LinkNav></HeaderStyled.LinkNav>
									</div>
									<div>
										<HeaderStyled.NavItemsList>
											{menuItems.map((menuItem, index: number) => (
												<HeaderStyled.ListItem
													key={index}
													title={menuItem.title}
													isActive={window.location.pathname === menuItem.link}
												>
													<a 
														translate="no"
														onClick={() => menuItem.customEvent(user)}>
														{menuItem.title}
													</a>
												</HeaderStyled.ListItem>
											))}
										</HeaderStyled.NavItemsList>
									</div>
								</HeaderStyled.FirstChildNavFixed>
							</>
						)}
					</HeaderStyled.NavFixed>

					<ModalRedirectComplementAccount
						isOpen={modalRedirectComplementAccountIsOpen}
						handleCloseModal={handleCloseModal}
					/>

					<ModalUserIsNotLogged
						queryParamValue={queryParamValue}
						isOpen={modalUserIsNotLogged}
						handleCloseModal={() => setModalUserIsNotLogged(false)}
					/>

					<OptinComunication
						isOpen={modalOptionComunication}
						handleCloseModal={() => setModalOptionComunication(false)}
					/>
				</>
			}
		</>
	);
};

export default Header;
